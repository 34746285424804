* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background: rgb(152,12,18);
  background: linear-gradient(349deg, rgba(152,12,18,1) 0%, rgba(50,0,3,1) 72.3%, rgba(28,0,0,1) 100%);
}

.myAudio {
  text-align: center;
  position: absolute;
  margin: auto;
  bottom: 20px;
  left: 0;
  right: 0;
}
.myAudio audio{
 display: block; 
 margin: auto;
}
.myAudio a {
  color: black;
  text-decoration: none;
  font-family: sans-serif;
}
.myAudio a div{
  display: inline-block;
  background-color: white;
  padding: 8px 15px;
  border-radius: 50px;
  box-sizing: border-box;
}
.myAudio a div:hover {
  color: white;
  background-color: #35512b;
}

audio:focus{
  outline: none;
}

iframe {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
}